import React, { Component } from 'react'
import Seo from '../components/seo'
import LogoColour from '../assets/images/logo-colour.svg'
import ApplicationForm from '../components/application-form'
import Link from '../utils/link'
import Fade from 'react-reveal'

class Careers extends Component {

  render() {

    return (
      <>
        <Seo title="Careers - I&D Group" description="We are currently expanding our business with a number of exciting projects on the horizon." />
        <section className='basic basic--careers'>
          <Fade bottom distance='40px'>
            <div className='basic__inner'>
              <div className='basic__content'>
                <Link to='/'><img className='basic__logo' src={LogoColour} alt='I&D Group' /></Link>

               {/*    <p>Currently Not Hiring</p>*/}
             <p>Having secured a number of exciting new projects for 2025, the I&D group are in search of a number of elite crane crews to man these projects. <br />If you are an exceptional tower crane operator or dogman experienced in building Structures for Tier 1 builders along with a can do attitude, <br />please complete the application form & we will be in contact.<br /><br /></p>
               {/*     <p className='small'>These job roles include - <b>Experienced Concreters, Jump-Core Workers, Formwork Laborers, Formwork Carpenters, Site Surveyors, Experienced Mobile & Static Concrete Pump Operators, Concrete Pump Operators, Concrete labourers</b></p> */}
              </div>
            </div>
          </Fade>
        </section>
      <section className='application'>
          <Fade bottom distance='40px'>
            <div className='application__inner'>
              <ApplicationForm />
            </div>
          </Fade>
        </section>
      </>
    )
  }
}

export default Careers